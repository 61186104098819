import React from 'react'
import '../Footer/Footer.css'

function Footer() {
  return (
    <div className="footer-container">
        <h5 className='copyright-footer'>&copy;Describeit.com - Copyright@2024</h5>
    </div>
  )
}

export default Footer